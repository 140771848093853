import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ArrowLink from "../components/arrowLink" 

const CTA = ({image,heading,subTitle,link = '/contact',text = 'Enquire Now'}) => (
  <div className="projects p40 mh-650 pos-rel flex flex-wrap overflow-hidden will--fade m-pt0  m-pb40     ">
    <div className="title mt40 pos-rel z-2 w-100 mba"> 
      <p className="m0 h3 max-650">{heading}</p>
    </div>
    <div className="content max-350 pos-rel z-2 mta mb0"> 
      <p className="m0 book text-grey mb40">{subTitle}</p>
      <ArrowLink to={link} text={text} />
    </div>  
    <GatsbyImage className="bg-image" image={image.gatsbyImageData} alt='CTA Banner Image' />
  </div>  
) 
export default CTA
