import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../components/link";
import SEO from "../components/seo"
import CTA from "../components/cta"

export default function Project({ data }) {
    var member = data.member
    return (
        <Layout footer='is-light' className={' bg--light is-dark '}>
            <SEO title={member.name} />
            <div className='content bg-white bg--light black'>
                <Spacer className='m-hide' />
                <div className='title-area p40'>
                    <h2 className='m0'>{member.name}</h2>
                    <h2 className='m0'>{member.companyPosition}</h2>
                </div>
                <Spacer className='m-hide' />
                <div className='pl40 pr40 m-pl20 m-pr20'>
                    <div className='bt1 pt20 pb20 m-pb0'>
                        <Link className='link op-link m-hide' to='/about'>Back</Link>
                    </div>
                </div>
                <div className='flex p40 m-wrap'>
                    <div className='w-40 m-100'>
                        <div className='w-75 m-100'>
                            <div className='ratio-1-2 pos-rel '>
                                <GatsbyImage alt={member.name} className='bg-image' image={member.image.gatsbyImageData} />
                            </div>
                        </div>
                    </div>
                    <div className='w-60 m-100 m-mt40'>
                        <div className='max-650' dangerouslySetInnerHTML={{ __html: member.content }}></div>
                        <div className='socials flex flex-wrap align-top mt40'>                           
                            {(member.email ?
                            <a href={'mailto:'+member.email} className="read--more link block mr60 mt20">
                                <div className="flex align-center">
                                    <span />
                                    <p className="mt0 mb0  m-underline">{member.email}</p>
                                </div>
                            </a>
                            :  null )}
                            
                            {(member.phone ?
                            <div className='m-100'>
                                <a href={'tel:'+member.phone} className="read--more link m-inline block mr60 mt20">
                                    <div className="flex align-center">
                                        <span />
                                        <p className="mt0 mb0  m-underline">{member.phone}</p>
                                    </div>
                                </a>
                            </div>
                            :  null )}

                            {(member.linkedin ? 
                                <div className='m-100 m-mt10'>
                                    <div className=' m-ml0 mt20 flex align-top'>
                                        <a href={member.linkedin} target="_blank" rel="noreferrer" className="read--more link flex align-center"><span />
                                            <p className="mt0 mb0  m-underline">LinkedIn</p>
                                        </a>
                                    </div> 
                                </div>
                                :  null )}

                        </div>
                    </div>
                </div>
                <Spacer />
                <div className='m-show pl20 pr20 mt20 pb20'>
                    <div className='bt1 pt20'>
                        <Link className='link ul-link black' to='/about'>Back</Link> 
                    </div>
                </div>
                 <Spacer />
            </div>
           
            <CTA heading={data.allDatoCmsHomepage.nodes[0].ctaTitle} subTitle={data.allDatoCmsHomepage.nodes[0].ctaSubtitle} image={data.allDatoCmsHomepage.nodes[0].ctaImage} />
        </Layout>
    )
}


export const query = graphql`
     query Crew($name: String ){ 
        member:datoCmsTeam(name: {eq: $name}){
            id
            name
            content
            linkedin
            phone
            email
            companyPosition
            image {
                gatsbyImageData
            }
        }
        allDatoCmsHomepage {
            nodes {
              ctaTitle
              ctaSubtitle
              ctaImage {
                gatsbyImageData
              }
            }
        }
     }
`